import { YAxisProps } from "recharts";
import { scaleSymlog } from "d3-scale";

export function yAxisConfig(options?: YAxisProps) {
  return {
    scale: "log",
    domain: [0.1, "auto"],
    stroke: "transparent",
    color: "transprent",
    ...options,
  } as any;
}

export default yAxisConfig;
